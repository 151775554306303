<template>
  <v-container>
    <v-card>
      <v-card-title class="black white--text">About Ink Inc</v-card-title>
      <v-card-text class="mt-4">
        <p>
          Ink Inc is a group of schoolfriends who just love comics and have created their
          own.
        </p>
        <p>
          The idea for the name came from the head director of Tech, Ed, and the idea for the
          main character Square Panda came from the boss, Matilda.
        </p>
        <p>
          The Square Panda series of comics includes characters such as Square Panda,
          Circle Monkey and Triangle Shark. All the comics are being hand drawn by our
          illustrators: Maya, Sofia, Sipho and Ashley.
        </p>
        <div class="text-center">
          <v-img
            class="d-inline-block"
            :src="require('@/assets/InkInc.png')"
            height="300"
            width="300"
          />
          <div class="display-3 ink-inc">Ink Inc.</div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Abput",
  data: () => ({}),
};
</script>
